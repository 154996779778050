<template>
  <app-page-layout :title="$t('hr.note.add_note')" icon="tw-hr-cost" @close="leavePage">
    <template #content>
      <validation-observer ref="observer">
        <v-row>
          <v-col cols="12">
            <validation-provider :name="$t('hr.note.title')" rules="required|max:255" v-slot="{ errors }">
              <v-text-field
                v-model="note.title"
                :label="$t('hr.note.title')"
                color="tertiary"
                clearable
                :error-messages="errors"
                counter="255"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('hr.note.body')" rules="required|max:512" v-slot="{ errors }">
              <v-textarea
                v-model="note.body"
                rows="2"
                :label="$t('hr.note.body')"
                color="tertiary"
                clearable
                :error-messages="errors"
                counter="512"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('hr.note.owner')" rules="required" v-slot="{ errors }">
              <employee-picker v-model="note.owner" :label="$t('hr.note.owner')" :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('hr.note.importance')" rules="required" v-slot="{ errors }">
              <importance-picker v-model="note.importance" clearable :error-messages="errors" />
            </validation-provider>
          </v-col>
          <v-col cols="12">
            <file-upload-picker
              v-model="note.url"
              :disabled="loading"
              :label="$t('hr.note.attachment')"
              storageType="NOTE"
              @send="cb => (onFileSend = cb)"
              onCreate
            />
          </v-col>
          <v-col cols="12">
            <validation-provider :name="$t('hr.note.description')" rules="max:512" v-slot="{ errors }">
              <v-textarea
                v-model="note.description"
                rows="1"
                :label="$t('hr.note.description')"
                color="tertiary"
                clearable
                :error-messages="errors"
                counter="512"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </validation-observer>
    </template>
    <template #footer>
      <app-page-footer create>
        <template #right>
          <v-btn text @click="validateAndSave" :loading="loading">{{ $t("buttons.save") }}</v-btn>
        </template>
      </app-page-footer>
    </template>
  </app-page-layout>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2020-12-31 15:44:15
   * @modify date 2020-12-31 15:44:15
   * @desc NoteCreate
   */
  import Note from "../model/Note";

  export default {
    name: "NoteCreate",
    components: {
      AppPageFooter: () => import("@/layouts/app-page-footer"),
      EmployeePicker: () => import("@/components/selectpickers/EmployeePicker"),
      ImportancePicker: () => import("@/components/selectpickers/ImportancePicker")
    },
    data: () => ({
      loading: false,
      note: new Note(),
      onFileSend: () => {}
    }),
    methods: {
      leavePage() {
        this.$router.replace({ name: "notes" });
      },
      validateAndSave() {
        this.$refs.observer.validate().then(valid => {
          if (valid) {
            this.loading = true;
            this.onFileSend().then(uploaded => {
              if (uploaded) {
                this.$api.noteService
                  .save(this.note)
                  .then(({ data }) => {
                    if (!data.error) {
                      this.$emit("success:save");
                      this.leavePage();
                    }
                  })
                  .catch(e => {
                    this.$helpers.showNotification(e.message);
                  })
                  .finally(() => {
                    this.loading = false;
                  });
              }
            });
          }
        });
      }
    }
  };
</script>

<style scoped></style>
